/* Below phone size */
@media screen and (max-width: 540px) {
  .page {
    background-color: white;
    height: 100%;
    padding: 5px;
  }

  .container {
    width: auto;
  }

  .header {
    background-color: #c7d0eb;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 15px;
    padding-bottom: 0px;
  }

  .item {
    align-items: center;
  }

  .contactInfo {
    text-align: center;
    font-size: 3rem;
    font-weight: bolder;
    color: #b02a2f;
    padding: 40px 15px;
    text-decoration: none;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #fad14b;
    font-size: 2.7rem;
    font-weight: bold;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
    margin: 0px;
  }

  .contactBody {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .subText {
    margin-bottom: 0px;
    text-align: center;
    font-size: 1.625rem;
    line-height: 1.3em;
    font-weight: 600;
    color: black;
  }

  .subTextLink {
    color: black;
    text-decoration: none;
    font-size: 1.625rem;
    font-weight: 600;
  }

  .subTitle {
    text-align: center;
    color: rgb(101, 101, 101);
    font-weight: 700;
    text-align: center;
  }

  .subHeaderText {
    padding: 8px 16px;
    color: rgb(15, 28, 73);
    background: rgb(221, 225, 238);
    border-radius: 4px;
    font-size: 1.125rem;
    line-height: 1.3em;
    font-weight: 500;
  }

  .submit {
    background-color: rgb(30, 55, 145);
    color: white;
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.4em;
    font-weight: 500;
    border-radius: 0.375rem;
    border-color: transparent;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .formBody {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

/* Above phone size */
@media screen and (min-width: 541px) {
  .page {
    background-color: white;
    height: 100%;
    padding: 30px;
  }

  .container {
    width: auto;
    margin: 0 10%;
  }

  .header {
    background-color: #c7d0eb;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 15px;
    padding-bottom: 0px;
  }

  .item {
    align-items: center;
  }

  .contactInfo {
    text-align: right;
    font-size: 3rem;
    font-weight: bolder;
    color: #b02a2f;
    padding: 40px 15px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #fad14b;
    font-size: 2.7rem;
    font-weight: bold;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
    margin: 0px;
  }

  .contactBody {
    margin: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .subText {
    margin-bottom: 0px;
    text-align: center;
    font-size: 1.625rem;
    line-height: 1.3em;
    font-weight: 600;
    color: black;
  }

  .subTextLink {
    color: black;
    text-decoration: none;
    font-size: 1.625rem;
    font-weight: 600;
  }

  .subTitle {
    text-align: center;
    color: rgb(101, 101, 101);
    font-weight: 700;
  }

  .subHeaderText {
    padding: 8px 16px;
    color: rgb(15, 28, 73);
    background: rgb(221, 225, 238);
    border-radius: 4px;
    font-size: 1.125rem;
    line-height: 1.3em;
    font-weight: 500;
    text-align: center;
  }

  .submit {
    background-color: rgb(30, 55, 145);
    color: white;
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.4em;
    font-weight: 500;
    border-radius: 0.375rem;
    border-color: transparent;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .formBody {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

/* Above phone size */
@media screen and (min-width: 1281px) {
  .page {
    background-color: white;
    height: 100%;
    padding: 30px;
  }

  .container {
    width: auto;
    margin: 0 10%;
  }

  .header {
    background-color: #c7d0eb;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 15px;
    padding-bottom: 0px;
  }

  .item {
    align-items: center;
  }

  .contactInfo {
    text-align: right;
    font-size: 3rem;
    font-weight: bolder;
    color: #b02a2f;
    padding: 40px 15px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #fad14b;
    font-size: 2.7rem;
    font-weight: bold;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
    margin: 0px;
  }

  .contactBody {
    margin: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .subText {
    margin-bottom: 0px;
    text-align: center;
    font-size: 1.625rem;
    line-height: 1.3em;
    font-weight: 600;
    color: black;
  }

  .subTextLink {
    color: black;
    text-decoration: none;
    font-size: 1.625rem;
    font-weight: 600;
  }

  .subTitle {
    text-align: center;
    color: rgb(101, 101, 101);
    font-weight: 700;
  }

  .subHeaderText {
    padding: 8px 16px;
    color: rgb(15, 28, 73);
    background: rgb(221, 225, 238);
    border-radius: 4px;
    font-size: 1.125rem;
    line-height: 1.3em;
    font-weight: 500;
  }

  .submit {
    background-color: rgb(30, 55, 145);
    color: white;
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.4em;
    font-weight: 500;
    border-radius: 0.375rem;
    border-color: transparent;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .formBody {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
