:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #1e3791;
    --foreground: #ededed;
  }
}

html,
body {
  background-color: var(--background);
}

body {
  color: var(--foreground);
  background: var(--background);
  margin: 0px;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
