/* Below phone size */
@media screen and (max-width: 540px) {
  .bouncer-card {
    background-color: white;
    font-weight: normal;
    margin: 10px;
  }

  .bouncer-card-image {
    width: 100%;
  }

  .bouncer-card-title {
    background-color: #5064ac;
    font-size: 1.2rem;
    font-weight: light;
    color: white;
    padding: 10px;
  }

  .bouncer-card-price {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background-color: #f1f1f1;
    border-top: 1px solid #f3f3f3;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #cf2e2e;
    font-weight: light;
    padding: 20px 10px;
  }

  .bouncer-card-body {
    color: black;
    font-size: 1rem;
    padding: 10px;
    max-width: 380px;
    line-height: 1.5rem;
  }
}
/* Above phone size */
@media screen and (min-width: 541px) {
  .bouncer-card {
    background-color: white;
    font-weight: normal;
    margin: 10px;
  }

  .bouncer-card:hover {
    box-shadow: rgba(50, 50, 93, 0.3) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.35) 0px 18px 36px -18px;
    position: relative;
    top: -3px;
  }

  .bouncer-card-image {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .bouncer-card-title {
    background-color: #5064ac;
    font-size: 1.2rem;
    font-weight: light;
    color: white;
    padding: 10px;
  }

  .bouncer-card-price {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background-color: #f1f1f1;
    border-top: 1px solid #f3f3f3;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #cf2e2e;
    font-weight: light;
    padding: 20px 10px;
  }

  .bouncer-card-body {
    color: black;
    font-size: 1rem;
    padding: 10px;
    max-width: 380px;
    line-height: 1.5rem;
  }
}
