/* Below phone size */
@media screen and (max-width: 540px) {
  .container {
    width: auto;
  }

  .header {
    background-color: #c7d0eb;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 15px;
  }

  .item {
    align-items: center;
  }

  .contactInfo {
    text-align: center;
    font-size: 3rem;
    font-weight: bolder;
    color: #b02a2f;
    padding: 40px 15px;
    text-decoration: none;
  }

  .smallBouncers {
    align-items: center;
    justify-content: center;
    background-color: #ea5252;
    padding: 15px;
  }

  .mediumBouncers {
    align-items: center;
    justify-content: center;
    background-color: #194bc1;
    padding: 15px;
  }

  .specialtyBouncers {
    align-items: center;
    justify-content: center;
    background-color: #fad14b;
    padding: 15px;
  }

  .hugeBouncers {
    align-items: center;
    justify-content: center;
    background-color: #ea5252;
    padding: 15px;
  }

  .premiumBouncers {
    align-items: center;
    justify-content: center;
    background-color: #194bc1;
    padding: 15px;
  }

  .smallBouncers p a {
    color: black;
    text-decoration: none;
  }

  .sectionTitle {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 2.5rem;
    text-shadow: 2px 2px #030303;
    text-align: center;
    width: 100%;
  }

  .smallBouncersText {
    display: flex;
    flex-flow: row wrap;
    font-size: 1.5rem;
    padding: 0 15px 15px 15px;
    text-align: center;
    color: black;
  }

  .extraInfoBlock {
    background-color: white;
    color: black;
    padding: 20px;
    text-align: center;
  }

  .FAQBlock {
    background-color: #5064ac;
    color: white;
    padding: 20px;
  }

  .Footer {
    background-color: #5064ac;
    width: 100%;
    text-align: center;
  }

  .BookNowContainer {
    padding: 50px 15px;
  }

  .BookNow {
    color: #fad14b;
    text-shadow: 3px 3px black;
    font-size: 3rem;
    font-weight: bold;
    text-decoration: underline;
    padding: 50px 15px;
  }

  .CopyRight {
    background-color: #7a8bc7;
    color: white;
    padding: 50px 15px 75px 15px;
  }

  .card {
    display: flex;
    flex-flow: row wrap;
    align-items: left;
    justify-content: left;
  }

  .col {
    width: 100%;
  }
}

/* Above phone size */
@media screen and (min-width: 541px) {
  .container {
    width: auto;
    margin: 0 10%;
  }

  .header {
    background-color: #c7d0eb;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 15px;
  }

  .item {
    align-items: center;
  }

  .contactInfo {
    text-align: right;
    font-size: 3rem;
    font-weight: bolder;
    color: #b02a2f;
    padding: 40px 15px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
  }

  .smallBouncers {
    align-items: center;
    justify-content: center;
    background-color: #ea5252;
    padding: 15px;
  }

  .mediumBouncers {
    align-items: center;
    justify-content: center;
    background-color: #194bc1;
    padding: 15px;
  }

  .specialtyBouncers {
    align-items: center;
    justify-content: center;
    background-color: #fad14b;
    padding: 15px;
  }

  .hugeBouncers {
    align-items: center;
    justify-content: center;
    background-color: #ea5252;
    padding: 15px;
  }

  .premiumBouncers {
    align-items: center;
    justify-content: center;
    background-color: #194bc1;
    padding: 15px;
  }

  .smallBouncers p a {
    color: black;
    text-decoration: none;
  }

  .sectionTitle {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 2.5rem;
    text-shadow: 2px 2px #030303;
    text-align: center;
    width: 100%;
    margin: 25px;
  }

  .smallBouncersText {
    display: flex;
    flex-flow: column wrap;
    font-size: 1.5rem;
    padding: 0 15px 15px 15px;
    text-align: center;
    color: black;
  }

  .extraInfoBlock {
    background-color: white;
    color: black;
    padding: 20px;
    text-align: center;
  }

  .FAQBlock {
    background-color: #5064ac;
    color: white;
    padding: 20px;
  }

  .Footer {
    background-color: #5064ac;
    width: 100%;
    text-align: center;
  }

  .BookNowContainer {
    padding: 50px 15px;
  }

  .BookNow {
    color: #fad14b;
    text-shadow: 3px 3px black;
    font-size: 3rem;
    font-weight: bold;
    text-decoration: underline;
  }

  .CopyRight {
    background-color: #7a8bc7;
    color: white;
    padding: 50px 15px 75px 15px;
  }

  .card {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
  }

  .col {
    width: 50%;
  }
}

/* Above phone size */
@media screen and (min-width: 1281px) {
  .container {
    width: auto;
    margin: 0 10%;
  }

  .header {
    background-color: #c7d0eb;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 15px;
  }

  .item {
    align-items: center;
  }

  .contactInfo {
    text-align: right;
    font-size: 3rem;
    font-weight: bolder;
    color: #b02a2f;
    padding: 40px 15px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
  }

  .smallBouncers {
    align-items: center;
    justify-content: center;
    background-color: #ea5252;
    padding: 15px;
  }

  .mediumBouncers {
    align-items: center;
    justify-content: center;
    background-color: #194bc1;
    padding: 15px;
  }

  .specialtyBouncers {
    align-items: center;
    justify-content: center;
    background-color: #fad14b;
    padding: 15px;
  }

  .hugeBouncers {
    align-items: center;
    justify-content: center;
    background-color: #ea5252;
    padding: 15px;
  }

  .premiumBouncers {
    align-items: center;
    justify-content: center;
    background-color: #194bc1;
    padding: 15px;
  }

  .smallBouncers p a {
    color: black;
    text-decoration: none;
  }

  .sectionTitle {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 2.5rem;
    text-shadow: 2px 2px #030303;
    text-align: center;
    width: 100%;
    margin: 25px;
  }

  .smallBouncersText {
    display: flex;
    flex-flow: column wrap;
    font-size: 1.5rem;
    padding: 0 15px 15px 15px;
    text-align: center;
    color: black;
  }

  .extraInfoBlock {
    background-color: white;
    color: black;
    padding: 20px;
    text-align: center;
  }

  .FAQBlock {
    background-color: #5064ac;
    color: white;
    padding: 20px;
  }

  .Footer {
    background-color: #5064ac;
    width: 100%;
    text-align: center;
  }

  .BookNowContainer {
    padding: 50px 15px;
  }

  .BookNow {
    color: #fad14b;
    text-shadow: 3px 3px black;
    font-size: 3rem;
    font-weight: bold;
    text-decoration: underline;
  }

  .CopyRight {
    background-color: #7a8bc7;
    color: white;
    padding: 50px 15px 75px 15px;
  }

  .card {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
  }

  .col {
    width: 25%;
  }
}
