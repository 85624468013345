/* Below phone size */
@media screen and (max-width: 540px) {
  .castle {
    display: flex;
    flex-flow: column;
    justify-content: left;
    color: black;
    background-color: #ffffff;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #fad14b;
    font-size: 2.7rem;
    font-weight: bold;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
  }

  .col {
  }

  .body {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 30px 10px;
  }

  .info {
    display: block;
    justify-content: center;
    padding: 40px 0;
    font-size: 1.15rem;
    line-height: 1.6rem;
  }

  .mainImg {
    width: 100%;
  }

  .thumbnails {
    padding: 10px 8px;
    width: 100px;
  }

  .hoverShadow:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 15px 0 rgba(0, 0, 0, 0.19);
    transition: 0.3s;
  }

  .thumbnails img {
    width: 100%;
  }

  .contact {
    margin: 50px 0 0 0;
    padding: 20px;
    font-size: 1rem;
    background-color: #c6ceec;
  }

  .gallery {
    display: block;
    min-width: 200px;
  }

  .mainImg {
    width: 100%;
  }

  .thumbnailGallery {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .thumbnailColumn {
    padding: 10px 8px;
  }
  .thumbnailColumn img {
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .price {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 5px;
  }

  .tagline {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 25px;
    font-weight: bold;
  }

  .castleInfo {
    margin-bottom: 20px;
  }

  .desc {
    margin-bottom: 20px;
  }

  .modal {
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modalContent {
    position: relative;
    margin: auto;
    padding: 0;
    width: 100%;
    max-width: 300px;
    text-align: center;
  }

  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
}

/* Below phone size */
@media screen and (min-width: 541px) {
  .castle {
    display: flex;
    flex-flow: column;
    justify-content: left;
    color: black;
    background-color: #ffffff;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #fad14b;
    font-size: 2.7rem;
    font-weight: bold;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
  }

  .col {
  }

  .body {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 30px 10px;
  }

  .info {
    display: block;
    justify-content: center;
    padding: 40px 0;
    font-size: 1.15rem;
    line-height: 1.6rem;
  }

  .mainImg {
    width: 100%;
  }

  .thumbnails {
    padding: 10px 8px;
    width: 100px;
  }

  .hoverShadow:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 15px 0 rgba(0, 0, 0, 0.19);
    transition: 0.3s;
  }

  .thumbnails img {
    width: 100%;
  }

  .contact {
    margin: 50px 0 0 0;
    padding: 20px;
    font-size: 1rem;
    background-color: #c6ceec;
  }

  .gallery {
    display: block;
    min-width: 200px;
  }

  .mainImg {
    width: 100%;
  }

  .thumbnailGallery {
    display: flex;
    flex-direction: row;
    float: left;
  }

  .thumbnailColumn {
    padding: 10px 8px;
    width: 100%;
  }
  .thumbnailColumn img {
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .price {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 5px;
  }

  .tagline {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 25px;
    font-weight: bold;
  }

  .castleInfo {
    margin-bottom: 20px;
  }

  .desc {
    margin-bottom: 20px;
  }

  .modal {
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modalContent {
    position: relative;
    margin: auto;
    padding: 0;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }

  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
}
/* Above phone size */
@media screen and (min-width: 1281px) {
  .castle {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: #ffffff;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #fad14b;
    font-size: 2.7rem;
    font-weight: bold;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
    margin: 15px;
  }

  .col {
    width: 45%;
  }

  .body {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 30px 10px;
  }

  .contact {
    margin: 50px 0 0 0;
    padding: 20px;
    font-size: 1rem;
    background-color: #c6ceec;
  }

  .gallery {
    display: block;
    min-width: 450px;
  }

  .mainImg {
    width: 100%;
  }

  .thumbnailGallery {
    display: flex;
    flex-direction: row;
    float: left;
  }

  .thumbnailColumn {
    padding: 10px 8px;
    width: 100%;
  }
  .thumbnailColumn img {
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .hoverShadow:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 15px 0 rgba(0, 0, 0, 0.19);
    transition: 0.3s;
  }

  .info {
    display: block;
    padding: 40px 0;
    font-size: 1.15rem;
    line-height: 1.6rem;
  }

  .price {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 5px;
  }

  .tagline {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 25px;
    font-weight: bold;
  }

  .castleInfo {
    margin-bottom: 20px;
  }

  .desc {
    margin-bottom: 20px;
  }

  .modal {
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modalContent {
    position: relative;
    margin: auto;
    padding: 0;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    margin-bottom: 200px;
  }

  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
}
